<template>

  <section class="container min-h-screen mx-auto flex">
    <div class="flex-grow flex flex-col justify-center p-6">

      <h1 class="text-2xl md:text-3xl lg:text-4xl font-bold">Widgets</h1>

      <button @click="$router.push('/dashboard')" class="flex items-center hover:underline">
        <div class="pr-1 opacity-50">
          <i class="text-xl uil uil-arrow-left"></i>
        </div>
        <div>Return to dashboard</div>
      </button>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8">
        <router-link to="/widget/add" class="bg-white p-8 border-dashed border-2 rounded-lg opacity-60 hover:opacity-100 transition duration-200 ease-in-out transform hover:scale-105">Add a new Widget +</router-link>

        <router-link v-for="widget in widgets" :key="widget.$id" :to="'/widget/' + widget.$id" class="bg-white p-8 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">
          {{ widget.name }}<br/>
          <button @click.prevent="handleDelete(widget)" class=" absolute top-0 right-0 p-2 ml-2 text-lg focus:outline-none transition duration-75 ease-in-out transform opacity-25 hover:opacity-100">
            <i class="uil uil-trash-alt"></i>
          </button>
        </router-link>

      </div>
    </div>
  </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Posts',
  components: {
    // SimplePost,
    // AddPost
  },
  data () {
    return {}
  },
  mounted () {
    this.fetchWidgets()
  },
  methods: {
    ...mapActions(['fetchWidgets', 'deleteWidget']),
    sort () {
      this.sorted = this.properties.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    },
    handleDelete (widget) {
      if (confirm('Are you sure you want to delete \'' + widget.name + '\'?')) {
        this.deleteWidget(widget.$id)
      }
    }
  },
  computed: {
    ...mapGetters(['widgets']),
    sortedProperties: function () {
      this.sort()
      return this.sorted
    }
  }
}
</script>
